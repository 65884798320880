
import { defineComponent } from 'vue'
import Markdown from 'vue3-markdown-it'

export default defineComponent({
  name: 'InfoPage',
  props: {
    pageTitle: String,
    pageContent: String
  },
  components: {
    Markdown
  },
  mounted () {
    this.scrollToTop()
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
})
