import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "info-content" }
const _hoisted_2 = { class: "info-header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "info-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.pageTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Markdown, { source: _ctx.pageContent }, null, 8, ["source"])
    ])
  ]))
}