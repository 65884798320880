
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import InfoPage from '@/components/InfoPage.vue'

export default defineComponent({
  name: 'Terms and Conditions',
  components: {
    InfoPage
  },
  computed: {
    ...mapGetters({
      infoLoaded: 'getInfoLoaded',
      infoData: 'getInfoData',
      infoMessage: 'getInfoMessage'
    })
  },
  mounted () {
    if (!this.infoLoaded) {
      this.loadPageData()
    }
  },
  methods: {
    loadPageData () {
      this.$store.dispatch('getInfoPagesData')
    }
  }
})
